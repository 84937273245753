import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby'
import Provider from 'components/Provider'
import Layout from "layouts/layout";
import SEO from 'components/seo'
import Header from 'components/Topic/Header';
import Footer from 'components/Topic/Footer';
import Button from 'components/Base/Button';
import Panel from 'components/Topic/Panel';
import ImageSwiper from 'components/Topic/ImageSwiper';
import LogoWall from 'components/Topic/LogoWall';

import "swiper/swiper-bundle.css";
import {
  Container
} from './index.module.less';
import ds2021Juries from '../../../data/ds2021/ds2021Juries';

let prizePeopleList = [
  {
    imgLink: require('assets/images/topic/ds2021star/group-people1.png').default,
    title: '数据驱动领军人物奖',
    desc: '具备非常强的数据意识，对推动企业数据体系建设和数据化进程方面有着主导性的推进作用。'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group-people2.png').default,
    title: '数据驱动菁英人物奖',
    desc: '在数据分析及数据驱动方面有着丰富的场景化落地经验并取得显著的对实际业务助推的成果。'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group-people3.png').default,
    title: '数据驱动创新人物奖',
    desc: '具备非常强的数据驱动意识，在数据分析及数据驱动方面，能够推动创新场景的应用。'
  },
]

let prizePeopleBigList1 = [
  {
    imgLink: require('assets/images/topic/ds2021star/group6.png').default,
    title: '数据驱动行业领军企业奖',
    desc: '在行业数字化转型和数字化经营上，具备很强的行业引领作用。'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group1.png').default,
    title: '数据驱动行业创新企业奖',
    desc: '在行业数据驱动和创新应用上具备新锐的应用场景和实践案例。'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group4.png').default,
    title: '数据驱动信创实践先锋企业奖',
    desc: '具备非常强的信创实践意识，在信创实践落地上有着行业领先作用。'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group3.png').default,
    title: '数字化经营特别企业奖',
    desc: '以业务应用为导向，在数字化经营的打造上建立起了较为完善的闭环。'
  },
]

let processList = [
  {
    imgLink: require('assets/images/topic/ds2021star/process-bg1.png').default,
    title: '征集',
    starTime: '2021.09.22',
    endTime: '2021.10.08'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/process-bg2.png').default,
    title: '评审',
    starTime: '2021.10.09',
  },
  {
    imgLink: require('assets/images/topic/ds2021star/process-bg3.png').default,
    title: '公示',
    starTime: '2021.10.10',
    endTime: '2021.10.12'
  },
  {
    imgLink: require('assets/images/topic/ds2021star/process-bg4.png').default,
    title: '颁奖',
    starTime: '2021.10.27',
  },
]

let  juriesList= [
  '清华大学大数据国家工程实验室',
  '全国信息安全技术标准工作委员会',
  '北京邮电大学信息安全学院',
  '中科院网络计算中心',
  '中国信息通信研究院',
  '经开区国家信创园',
  '海淀商联会技术专家委员会',
]

let imageList = [
  require('assets/images/topic/ds2021star/img1.png').default,
  require('assets/images/topic/ds2021star/img2.png').default,
  require('assets/images/topic/ds2021star/img3.png').default,
  require('assets/images/topic/ds2021star/img4.png').default,
  require('assets/images/topic/ds2021star/img5.png').default,
  require('assets/images/topic/ds2021star/img6.png').default,
]


interface DS2021StarProps {
  data?: any;
}

const DS2021Star: React.FC<DS2021StarProps> = (props) => {
  const {
    data
  } = props;
  const [logoWall, setLogoWall] = useState<any>();

  useEffect(() => {
    setLogoWall(data?.allDs2021StarCandidatesYaml?.nodes);
  }, [])


  return (
    <Layout>
      <Provider>
        <div className={Container}>
          <SEO 
            title="神策数据｜2021星斗奖典礼｜神策数据驱动大会"
            description="星斗奖的诞生，旨在鼓励走在数据驱动实践前沿的企业机构及突出贡献者，通过数据驱动大会这一平台共同分享典型案例成果"
            keywords="星斗奖,神策数据星斗奖,2021数据驱动大会,神策数据驱动大会"
            saTitle="首页-2021数据驱动大会-星斗奖"
          />
          <Header />
          <main>
            <div className="banner-wrap">
              <div className="banner-main-content">
                <div className="banner-text-content">
                  <div className="bt-title">星斗奖</div>
                  <div className="bt-title-two">数据如浩瀚繁星，驱动人类勇往前行</div>
                  <div className="bt-text">神策 2021 数据驱动大会</div>
                  <div className="bt-text-small">Sensors Data-driven Conference 2021</div>
                  <div className="bt-button-content">
                    <Button btnType="blue" href="http://sensorsform.mikecrm.com/REq5iFT" target="_blank">企业报名</Button>
                    <Button className="bt-margin" btnType="blue" plain href="http://sensorsform.mikecrm.com/NPbgr4l" target="_blank">个人报名</Button>
                  </div>
                </div>
                <div className="banner-img-content">
                  <img className="banner-img" src={require('assets/images/topic/ds2021star/banner-left.png').default} alt=''/>
                </div>
              </div>
            </div>
            <Panel type="primary" title="星斗奖简介" enTitle="ABOUT US">
              <div className="about-us-content">
                <div className="about-us-text">
                  <div>“重构中国互联网数据根基”是神策数据的企业愿景，六年历练与成长让神策在中国大数据领域稳固扎根，并致力于将数据驱动理念与实操经验传播分享至各个行业企业，已为超过 2000 家行业头部企业提供了数字化服务。</div>
                  <div><span>星斗奖的诞生旨在鼓励走在数据驱动实践前沿的企业机构及突出贡献者</span>，通过数据驱动大会这一平台更积极地传播交流数据驱动行业发展的新态势，共同分享典型案例成果，进而推动整个中国大数据基础建设与行业应用进程。</div>
                  <div>2017-2020 年，已历经四届星斗奖的评选，数百家在数据驱动前沿的各行业头部企业参与和获得了星斗奖。2021 年 10 月，<span>神策数据携手各位评审专家</span>即将进行第五届星斗奖的评选和颁奖。</div>
                </div>
                <div className="about-us-img">
                  <ImageSwiper dataSource={imageList}/>
                </div>
              </div>
            </Panel>
            <Panel type="primary" title="评审专家机构（排名不分先后）" enTitle="JURIES">
              <div className="juries-wrap">
                <div className="juries-content">
                  {
                    juriesList?.map((ele, index) => {
                      return (
                          <div key={index} className="juries-content-every">
                            {ele}
                          </div>
                      )
                    })
                  }
                </div>
              </div>

            </Panel>
            <Panel type="primary"  title="星斗奖奖项设置" enTitle="TYPES OF AWARDS">
              <div className="prize-content">
                <div className="prize-title">年度数据驱动人物类奖项</div>
                <div className="prize-detail">
                  {
                    prizePeopleList?.map((ele, idx) => {
                      return (
                        <div key={idx} className="prize-detail-every">
                          <img src={ele.imgLink} alt='' />
                          <div className="prize-no-content">
                            <div className="prize-detail-title">{ele.title}</div>
                            <div className="prize-detail-desc">{ele.desc}</div>
                            <Button className="prize-detail-button pc-content" btnType="blue" size="small" href="http://sensorsform.mikecrm.com/NPbgr4l" target="_blank">立即报名</Button>
                          </div>
                        </div>
                      )
                    })
                  }
                  <div className="phone-content button-center-content">
                    <Button className="prize-detail-button" btnType="blue" size="small" href="http://sensorsform.mikecrm.com/NPbgr4l" target="_blank">立即报名</Button>
                  </div>
                </div>
              </div>
              <div className="prize-content primary-big">
                <div className="prize-title">年度数据驱动企业类奖项</div>
                <div className="prize-detail">
                  {
                    prizePeopleBigList1?.map((ele, idx) => {
                      return (
                        <div key={idx} className="prize-detail-every">
                          <img src={ele.imgLink} alt='' />
                          <div className="prize-no-content">
                            <div className="prize-detail-title">{ele.title}</div>
                            <div className="prize-detail-desc">{ele.desc}</div>
                            <Button className="prize-detail-button pc-content" btnType="blue" size="small" href="http://sensorsform.mikecrm.com/REq5iFT" target="_blank">立即报名</Button>
                          </div>
                        </div>
                      )
                    })
                  }
                  <div className="phone-content button-center-content">
                    <Button className="prize-detail-button" btnType="blue" size="small" href="http://sensorsform.mikecrm.com/REq5iFT" target="_blank">立即报名</Button>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel className="margin-style-title" type="primary" title="奖项征集流程" enTitle="PROCESS">
              <div className="process-detail">
                {
                  processList?.map((ele, index) => {
                    return (
                      <div className="process-detail-every-content" key={index}>
                        <div className="process-detail-every"
                            style={{'background': `url(${ele.imgLink})`, 'backgroundSize': '100% 100%'}}>
                          <div className="process-detail-title">{ele.title}</div>
                          <div className="process-detail-time">{ele.starTime}</div>
                          {
                            ele.endTime ? <div className="process-detail-time process-detail-end">{ele.endTime}</div> : null
                          }
                        </div>
                        {
                          index + 1 < processList.length ?  <div className="process-empty" /> : null
                        }
                      </div>
                    )
                  })
                }
              </div>
            </Panel>
            <Panel className="margin-style-title" type="primary" title="候选企业" enTitle="CANDIDATES">
              <LogoWall logoList={logoWall}/>
            </Panel>
          </main>
          <Footer />
        </div>
      </Provider>
    </Layout>
  );
}

export const query = graphql`
query {
  allDs2021StarCandidatesYaml {
    nodes {
      title
      file {
        childImageSharp {
          gatsbyImageData(formats: PNG)
        }
      }
    }
  }
}`

export default DS2021Star;