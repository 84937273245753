import React, { useEffect } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import classnames from 'classnames';
import './index.module.less';

interface LogoWallProps {
  className?: string;
  logoList: Array<any>;
}

interface everyObj {
  title: string,
  file: any,
}

const LogoWall: React.FC<LogoWallProps> = (params) => {
  const {
    className,
    logoList = [],
  } = params;

  const cnames = classnames("logo-wall", className );

  return (
    <section className={cnames}>
      <div className="logo-content">
        {
          logoList?.map((item: everyObj, idx) => {
            const image = getImage(item.file);
            return (
              <div key={idx} className="logo-every">
                <GatsbyImage className="logo-img" image={image as IGatsbyImageData} alt={item.title} />
              </div>
            )
          })
        }
      </div>
    </section>
  );
}

export default LogoWall;