import React, {useRef, useState} from 'react';
import classnames from 'classnames';
import './index.module.less';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

interface ImageSwiperProps {
    className?: string;
    dataSource: Array<any>
}


const ImageSwiper: React.FC<ImageSwiperProps> = (params) => {
    const {
        className,
        dataSource,
    } = params;

    let autoplay = {
        delay: 2000,
    }

    const cnames = classnames("topic-image-swiper", className );

    return (
        <section className={cnames}>
            <Swiper
                navigation
                spaceBetween={110}
                pagination={{ clickable: true }}
                autoHeight={true}
                autoplay={autoplay}
            >
                {
                    dataSource?.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className='topic-img-content'>
                                    <img className="se-img"
                                         src={item}
                                         alt=''
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    );
}

export default ImageSwiper;